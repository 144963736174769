import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Layout from "../components/Layout";

async function fetchData() {
  const response = await fetch('/data/portfolio.json');
  const data = await response.json();
  return data.portfolio;
}

function Portfolio () {
    const [data, setData] = useState([]);
    
    useEffect(() => {
        fetchData().then((data) => setData(data));
    }, []);

    return (
        <Layout>
            <div className="max-w-7xl m-auto text-center mx-10 md:mx-48 sm:mx-20 my-10">
                <div className="text-5xl pb-5">My Portfolio</div>
                <div className="text-xl">Welcome to my portfolio.<br/>
                This includes all my professional work, freelance and side projects.<br/>
                Explore my portfolio to learn more about what I have done and what I do.</div>
            </div>
            <div className="max-w-7xl
                grid grid-cols-4 gap-4 mx-10
                max-sm:grid-cols-1
                max-md:grid-cols-2
                ">
                { data.map(({title, image}, index) => (
                    <Link key={index} to={`/portfolio/${index}`}>
                        <div key={index} className="w-full h-52 grow
                        bg-[image:var(--image-url)] bg-cover bg-center text-center shadow-lg rounded-t-2xl overflow-hidden"
                            style={{'--image-url': `url(${ image })`}} >
                            <div className="bg-black text-white text-2xl inset-x-0 bottom-0">{ title }</div>
                        </div>
                    </Link>
                ))}
            </div>
        </Layout>
    )
}

export default Portfolio;
