import { useEffect } from "react";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import PortfolioItem from "./pages/PortfolioItem";
import Technologies from "./pages/Technologies";
import Contact from "./pages/Contact";
import {
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-X08Q1BLC6W";

function App() {
    const location = useLocation();
    ReactGA.initialize(TRACKING_ID);
    useEffect(() => {
        ReactGA.set({ page: location.pathname });
    }, [location]);
    return (
        <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames="fade" timeout={300}>
                <Routes location={location}>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/portfolio" element={<Portfolio />} />
                    <Route exact path="/portfolio/:slug" element={<PortfolioItem />} />
                    <Route exact path="/technologies" element={<Technologies />} />
                    <Route exact path="/contact" element={<Contact />} />
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    );
}

export default App;
