import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";

function Layout({children}) {
    return (
        <div className="bg-gray-100">
            <div className="shadow-lg max-w-7xl m-auto bg-white min-h-screen">
                <Header/>
                { children }
                <Footer/>
            </div>
        </div>
    )
}

export default Layout;
