import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from "../components/Layout";

async function fetchData() {
    const response = await fetch("/data/portfolio.json");
    const data = await response.json();
    const portfolio = data.portfolio.filter((data) => data.type === "Professional Work History");
    return portfolio;
}

function Home() {
    const [data, setData] = useState([]);
    useEffect(() => {
        fetchData().then((data) => setData(data));
    }, []);

    return (
        <Layout>
            <div className="bg-white max-w-7xl m-auto text-center md:pt-10 py-5">
                <div className="text-6xl pb-4">I'M JARON J KING</div>
                <div className="text-lg">Software engineer with a passion for innovation, design and technology</div>
            </div>

            <div className="max-w-7xl p-10 text-center bg-white">
                <div className="mb-5 text-3xl">
                    Personal Statement
                </div>
                <div className="md:mx-52 sm:mx-20 mx-10 text-xl">
                    I have been developing applications Professionally for about 8 years.
                It all began with an interest I had in middle school that grew into a full blown passion.
                I love software engineering, I enjoy the thrill of overcoming obstacles, tackling a feature,
                implementing a fix and squashing a bug.
                </div>
            </div>

            <div className="text-3xl text-center max-w-7xl pb-5">
                Professional History
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 rounded-t-3xl overflow-hidden mx-10 gap-3 bg-stone-300">
                { data.map(({title, image}, index) => (
                    <Link key={index} to={`/portfolio/${index}`} className="grow
                        bg-[image:var(--image-url)] bg-cover text-center col-span-1 h-72 bg-white"
                        style={{'--image-url': `url(${ image })`}}>
                        <div className="bg-black text-white text-3xl py-2 ">
                            { title }
                        </div>
                    </Link>
                ))}
            </div>

            <div className="max-w-7xl p-10 pt-20">
                <div className="text-3xl text-center">
                    Resume
                </div>
                <div className="m-x20 md:mx-40 text-lg ">
                    Freelance<br/>
                    Software Engineer<br/>
                    September 2023 - Present (2 months)<br/>
                    Sacramento, California, United States<br/><br/>
                    Landing pages and web applications built in ReactJS, Tailwind, Firebase and
                    hosted on Netlify.<br/>
                    Courier Business with a custom booking form built with payment processing by
                    Stripe. Custom calendar select with Firebase scheduling logic to help with no
                    overlapping deliveries. Tailor made with TailWindCSSfor optimal web usability
                    and device support.<br/>
                    Dog services company built with the same technologies as before, ReactJs,
                    Tailwind and Firebase all hosted on Netlify.<br/><br/><br/>
                    Luxer One<br/>
                    Software Engineer<br/>
                    July 2017 - September 2019 (2 years 3 months)<br/>
                    Sacramento, California Area<br/><br/>
                    Improved and maintained customer facing and company facing CakePhp 2
                    application that would interface with smart locker technologies.<br/>
                    Rewrite of integration software that would interface with dozens of Property
                    Management software.<br/>
                    The integration application would sync data daily with APIs or file transfer.
                    Front end technologies included VuejS and Mustache templates along with
                    Vanilla JavaScript.<br/>
                    Optimizing MySQL queries to adjust to the growth of database tables,
                    leveraging MySQL Explain.<br/>
                    Used PHPUnit for test driven development.<br/><br/><br/>
                    Dynamic Vision Inc.<br/>
                    Software Engineer<br/>
                    February 2016 - May 2017 (1 year 4 months)<br/>
                    Temecula, CA<br/><br/>
                    Improved and maintained an insurance processing application for the state of
                    Ohio. Built on a Laravel backend and AngularJs 2. Helped with migrating a
                    MicosoftSQL to MYSQL database of terrabytes of information using MySQL
                    workbench.<br/>
                    Experience with Firebase and Amazon services.<br/><br/><br/>
                    Freelance<br/>
                    Software Engineer<br/>
                    February 2015 - March 2016 (1 year 2 months)<br/><br/>
                    Developed web applications with Symfony, Laravel, Bootstrap 3 and 4. Also
                    includes contemporary JavaScript libraries for front-end designs. Projects
                    included a telehealth web application, craigslist like application for home
                    cleaning and custom CMS's. Some of the applications included a checkout
                    process that I leverages Stripe api to manage.<br/><br/><br/>
                    AP Media, Inc.<br/>
                    Software Engineer / Front-end Developer<br/>
                    March 2014 - February 2015 (1 year)<br/>
                    San Diego, Ca<br/><br/>
                    Helped develop an Ad delivery application and other varying Web applications
                    including standalone product stores using Limelight API. Developed using
                    Symfony2, Git, Javascript, Jquery, Bootstrap, TWIG and launched and used
                    LAMP environment.<br/>
                    Created landing pages, focus on maximizing conversions and data analysis on
                    user engagement and flow.<br/><br/><br/>
                    Adconion Direct<br/>
                    Email Marketing Manager<br/>
                    December 2012 - February 2014 (1 year 3 months)<br/>
                    San Diego, CA<br/><br/>
                    Managed multiple mailing domains and worked with numerous Fortune 500
                    companies. Created many in house solutions and analytical tools for both
                    knowledge building and research. Worked with Server Security and helped
                    create and maintain a block log, protecting an environment that receives over
                    1,000,000 unique visitors per day.<br/><br/><br/>
                    Regents of the university of California, Davis<br/>
                    IT Assistant IV<br/>
                    September 2010 - July 2012 (1 year 11 months)<br/>
                    ITS, UC Davis<br/><br/>
                    Worked in the ITS department doing Web Development with PHP, WordPress,
                    WordPress themes, HTML, CSS, and JavaScript.
                    Social Media management of department accounts and Facebook API.
                    Web Banner Design, Video editing and PowerPoint slideshows.
                    Online Course development with Flash and Adobe Captivate.
                    Experience with Plone CMS. Video editing for web courses and webinars.<br/>
                </div>
            </div>

            <div className="max-w-7xl p-10">
                <div className="text-3xl text-center">
                    Education
                </div>
                <div className="m-20 md:mx-40 text-lg">
                    University of California, Davis<br/>
                    Bachelor's Degree, Philosophy · (2010 - 2012)<br/><br/>
                    College of the Desert<br/>
                    AA, Political Science · (2006 - 2009)
                </div>
            </div>

        </Layout>
    )
}

export default Home;
