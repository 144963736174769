import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import TechnologyPieChart from '../components/TechnologyPieChart';

async function fetchData(slug) {
    const response = await fetch("/data/portfolio.json");
    const data = await response.json();
    return data.technologies[0];
}

function Technologies () {
    const [data, setData] = useState([]);
    useEffect(() => {
        fetchData().then((data) => setData(data));
    }, []);

    return (
        <Layout>
            <div className="text-5xl pt-10 pb-5 text-center">
                Technologies
            </div>
            <div className="text-center pb-10">
                (Rollover Piechart to highlight)
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-20 pb-32">
                    { Object.keys(data).map((technology, index) => (
                        <TechnologyPieChart key={index} technology={technology}/>
                    ))}
            </div>
        </Layout>
    )
}

export default Technologies;
