import React, { useState, useEffect } from 'react';

import Layout from "../components/Layout";
import { useParams } from 'react-router-dom';

async function fetchData(slug) {
    const response = await fetch('/data/portfolio.json');
    const data = await response.json();
    return data.portfolio[slug];
}

function PortfolioItem () {
    const { slug } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData(slug).then((data) => setData(data));
    }, [ slug ]);

    return (
        <Layout>
            <div className="max-w-7xl m-auto text-center mx-10 md:mx-20 sm:mx-20 my-10">
                <div className="grid gird-cols-1 md:grid-cols-5 pb-5">
                    <div className="md:col-span-3 text-left">
                        <div className="text-4xl text-center">
                            { data.title }
                        </div>
                        <div>
                            <span className="text-xl">Project Type:</span><br/>
                            <span className="text-lg">{ data.type }</span><br/><br/>
                            <span className="text-xl">Date:</span><br/>
                            <span className="text-lg">{ data.date }</span><br/><br/>
                            <span className="text-xl">Skills:</span><br/>
                            <span className="text-lg">{ data.skills }</span><br/><br/>
                            <a className="text-blue-600 underline hover:text-blue-700" target="_blank" rel="noreferrer" href={ data.link }>{data.link}</a>
                        </div>
                    </div>
                    <div className="md:col-span-2 text-left pt-10 text-xl" dangerouslySetInnerHTML={{__html: data.description }}></div>
                </div>

                <div className="grid grid-cols-2 px-20">
                    <img src={ data.image } className="col-span-2 md:col-span-1" alt={ data.description }/>
                </div>

                <div>

                </div>
            </div>

        </Layout>
    )
}

export default PortfolioItem;
