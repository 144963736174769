import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function Header() {

    const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false

    return (
    <header className="inset-x-0 top-0 z-50 max-w-7xl m-auto">
        <div className="flex flex-col text-center">
            <Link to="/" className="m-auto text-4xl pt-4 pb-1">
                <span>Jaron J King</span>
            </Link>
        </div>
        <nav className="flex p-1 lg:px-8 bg-stone-200" aria-label="Global">
            <div className="flex lg:hidden">
                <button type="button"
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    onClick={() => setIsNavOpen((prev) => !prev)}
                    >
                    <span className="sr-only">Open main menu</span>
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
            </div>
            <div className="hidden lg:flex lg:flex-col w-full">
                <div className="justify-center align-items-center text-center text-md">
                    <Link to="/" className="font-semibold leading-6 text-gray-900 mx-5">Home</Link>
                    <Link to="/portfolio" className="font-semibold leading-6 text-gray-900 mx-5">Portolio</Link>
                    <Link to="/technologies" className="font-semibold leading-6 text-gray-900 mx-5">Technologies</Link>
                    <Link to="/contact" className="font-semibold leading-6 text-gray-900 mx-5">Contact</Link>
                </div>
            </div>
        </nav>

        <div className={`lg:hidden ${isNavOpen ? "" : "hidden"}`} role="dialog" aria-modal="true">
            <div className="fixed inset-0 z-50"></div>
            <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                    <Link to="/" className="-m-1.5 p-1.5">
                        <span className="float-right pt-2">Jaron J King</span>
                    </Link>
                    <button type="button"
                        className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        onClick={() => setIsNavOpen(false)}>
                        <span className="sr-only">Close menu</span>
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                        <div className="space-y-2 py-6">
                            <Link to="/" className="text-sm font-semibold leading-6 text-gray-900 mx-5">Home</Link>
                            <Link to="/portfolio" className="text-sm font-semibold leading-6 text-gray-900 mx-5">Portolio</Link>
                            <Link to="/technologies" className="text-sm font-semibold leading-6 text-gray-900 mx-5">Technologies</Link>
                            <Link to="/contact" className="text-sm font-semibold leading-6 text-gray-900 mx-5">Contact</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    );
}

export default Header;
