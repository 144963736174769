
function Footer() {
    return (
        <footer className="max-w-7xl m-auto text-center bottom-0 bg-stone-200 py-1 mt-10">
            <div>Jaron J King</div>
            <div>jaronjking@gmail.com</div>
            <div>©2023 by Jaron J King.  Proudly created with TailWindCSS</div>
        </footer>
    )
}

export default Footer;
