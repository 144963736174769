import React, { useState, useEffect } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

async function fetchData(slug) {
    const response = await fetch("/data/portfolio.json");
    const data = await response.json();
    return data.technologies[0][slug];
}

function TechnologyPieChart ({technology}) {
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(0);
    useEffect(() => {
        fetchData(technology).then((data) => setData(data));
    }, [technology]);

	return (
		<div className="grid grid-cols-2 gap-4">
            <div className="">
                <div className="text-3xl">{technology}</div>
                <div className="text-xl">
                	{ data.map(({title, color}, index) => (
                		<div key={index} className="flex flex-row">
                			<div className="w-5 h-5 m-1" style={{ backgroundColor: `${color}` }}></div>
                			<div className={`${selected === index && " bg-gray-400/50"}`}>{title}</div>
            			</div>
                	))}
                </div>
            </div>
            <div className="">
	            <PieChart
	                data={data}
	                radius={40}
	                segmentsShift={(index) => (index === selected ? 3 : 0)}
	                onMouseOver={(event, index) => {
	                    setSelected(selected === index ? undefined : index);
	                }}
	                />
	        </div>
        </div>
	)

}

export default TechnologyPieChart;
