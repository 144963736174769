
import Layout from "../components/Layout";
function Contact () {
    return (
        <Layout>
            <div className="pt-10 text-5xl text-center">
                Contact
            </div>
            <div className="text-center pt-10 pb-5">
                Feel free to contact me:<br/>
                Email: Jaronjking@gmail.com<br/>
            </div>
            <div className="pb-10">
                <img src="./images/onComputer.jpeg" alt="man on computer" className="m-auto w-1/2"/>
            </div>  
        </Layout>
    )
}

export default Contact;
